import React from "react";
import { Route, Routes } from "react-router-dom";
import ModelsPage from "../screens/ModelsPage/ModelsPage";
import ModelScreen from "../screens/ModelScreen/ModelScreen";

const ModelsRoute = () => {
  return (
    <Routes>
      <Route path="/" element={<ModelsPage />} />
      <Route path="model" element={<ModelScreen />} />
    </Routes>
  );
};

export default React.memo(ModelsRoute);
