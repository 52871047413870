import React, { useCallback } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Button,
} from "@fluentui/react-components";
import { useNavigate } from "react-router-dom";
import { FaAppStoreIos, FaDiscord, FaGooglePlay } from "react-icons/fa";
import { TiWarning } from "react-icons/ti";
import StripeButton from "./StripeButton";
import { useWindowSize } from "@uidotdev/usehooks";
import { MdLogout } from "react-icons/md";
import { signOut } from "firebase/auth";
import { auth } from "../../hooks/useFirebase";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
    ...shorthands.flex(1),
    ...shorthands.padding("20px"),
    ...shorthands.gap("20px"),
    boxSizing: "border-box",
    flexGrow: 1,
    width: "100%",
    height: "100%",
    backgroundColor: "white",
    alignItems: "center",
  },
  actionBtn: {
    ...shorthands.flex(1),
    minHeight: "100px",
    "@media (max-width: 850px)": {
      width: "100%",
    },
  },
  actionBtnWrap: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    ...shorthands.gap("5px"),
    "@media (max-width: 850px)": {
      flexDirection: "column",
      ...shorthands.gap("10px"),
    },
  },
  hstack: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("10px"),
  },
});

function NewMyProfileBody() {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const navigate = useNavigate();

  const handleDiscord = useCallback(() => {
    window.open("https://discord.gg/fY6xhAx4ck", "_blank");
  }, []);

  const handleReportIssue = useCallback(() => {
    window.open(
      "mailto:aarontufts.dev@gmail.com?subject=3D%20GO%20-%20App%20Feedback",
      "_blank"
    );
  }, []);

  const handleLogout = useCallback(() => {
    signOut(auth);
    navigate(`/models`);
  }, [navigate]);

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground3 }}
    >
      <div className={styles.actionBtnWrap}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: "100%",
          }}
        >
          <div className={styles.hstack}>
            <Button
              className={styles.actionBtn}
              size="large"
              onClick={handleDiscord}
              icon={<FaDiscord />}
            >
              Join Discord
            </Button>
            <Button
              className={styles.actionBtn}
              size="large"
              onClick={handleReportIssue}
              icon={<TiWarning />}
            >
              Report Issue
            </Button>
          </div>
          <div className={styles.hstack}>
            <a
              href="https://apps.apple.com/us/app/thingiverse-printables-3d-go/id6462052942"
              style={{ display: "flex", textDecoration: "none" }}
              className={styles.actionBtn}
            >
              <Button
                className={styles.actionBtn}
                size="large"
                icon={<FaAppStoreIos />}
              >
                iOS App
              </Button>
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.thingiverse.explore&hl=en_US"
              style={{ display: "flex", textDecoration: "none" }}
              className={styles.actionBtn}
            >
              <Button
                className={styles.actionBtn}
                size="large"
                icon={<FaGooglePlay />}
              >
                Android App
              </Button>
            </a>
          </div>
        </div>
        {windowSize.width < 850 && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "10px",
              width: "100%",
            }}
          >
            <div
              style={{
                margin: "0px -10px",
              }}
            >
              <StripeButton />
            </div>
            <Button icon={<MdLogout size={15} />} onClick={handleLogout}>
              Logout
            </Button>
          </div>
        )}
      </div>
    </div>
  );
}

export default React.memo(NewMyProfileBody);
