import React, { useCallback } from "react";
import {
  makeStyles,
  teamsDarkTheme,
  shorthands,
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionPanel,
  Checkbox,
  RadioGroup,
  Radio,
  Text,
  Dropdown,
  Option,
} from "@fluentui/react-components";
import { useWindowSize } from "@uidotdev/usehooks";
import Lottie from "lottie-react";
import AgentIcon from "../../Components/AgentIcon/AgentIcon";
import cube from "../../Components/logo-lottie1.json";
import { FaSortAmountDown } from "react-icons/fa";

const useStyles = makeStyles({
  root: {
    display: "flex",
    height: "100%",
    ...shorthands.flex(1),
    maxWidth: "250px",
    boxSizing: "border-box",
    ...shorthands.borderRight(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    ...shorthands.borderLeft(
      "1px",
      "solid",
      teamsDarkTheme.colorNeutralStroke2
    ),
    alignItems: "flex-start",
    "@media (max-width: 850px)": {
      ...shorthands.flex(0),
      //   maxWidth: "100%",
      maxWidth: "100%",
      width: "100%",
      ...shorthands.borderRight("none"),
      ...shorthands.borderLeft("none"),
      ...shorthands.borderBottom(
        "1px",
        "solid",
        teamsDarkTheme.colorNeutralStroke2
      ),
    },
  },
  mainDrawer: {
    display: "flex",
    flexDirection: "column",
    position: "sticky",
    top: "0px",
    ...shorthands.flex(1),
    ...shorthands.gap("10px"),
    ...shorthands.padding("20px", "0px"),
  },
  brandWrap: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    ...shorthands.padding("10px", "0px"),
    ...shorthands.flex(1),
  },
  agentWrap: {
    display: "flex",
    flexDirection: "row",
    ...shorthands.gap("5px"),
  },
  brandText: {
    fontSize: "20px",
    fontWeight: "bold",
  },
  optionsWrap: {
    display: "flex",
    flexDirection: "column",
  },
  accordian: {
    backgroundColor: "red",
  },
  agentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  catH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    ...shorthands.gap("5px"),
  },
  accordionBody: {
    backgroundColor: teamsDarkTheme.colorNeutralBackground3,
    ...shorthands.padding("10px"),
    display: "flex",
    flexDirection: "column",
    ...shorthands.gap("5px"),
    ...shorthands.borderRadius("5px"),
    boxShadow: `0px 0px 2px 0px ${teamsDarkTheme.colorNeutralStroke1}`,
  },
  mobileDrawer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    ...shorthands.flex(1),
    backgroundColor: teamsDarkTheme.colorNeutralBackground2,
  },
  mobileOptionsWrap: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    ...shorthands.gap("5px"),
    ...shorthands.padding("10px"),
  },
  mobileAgentH: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
});

function NewSavedFilterSort({ sort, setSort, providers, setProviders }) {
  const styles = useStyles();
  const windowSize = useWindowSize();
  const lottieRef = React.useRef(null);

  const agentIconStyles = {
    width: "30px",
    height: "30px",
    borderRadius: "7px",
  };

  const lottieStyle = {
    width: `40px`,
    height: "40px",
    // cursor: "pointer",
    marginRight: "10px",
    borderRadius: "5px",
  };

  const handleUpdateProviders = useCallback(
    (val, agent) => {
      setProviders((prev) => {
        return { ...prev, [agent]: val };
      });
    },
    [setProviders]
  );

  return (
    <div
      className={styles.root}
      style={{ backgroundColor: teamsDarkTheme.colorNeutralBackground2 }}
    >
      {windowSize.width > 850 ? (
        <div className={styles.mainDrawer}>
          {/* heading info */}
          <div className={styles.brandWrap}>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Lottie
                animationData={cube}
                lottieRef={lottieRef}
                style={lottieStyle}
              />
              <p className={styles.brandText}>| 3D GO</p>
            </div>
            <div className={styles.agentWrap}>
              <AgentIcon agent="thingiverse" styles={agentIconStyles} />
              <AgentIcon agent="printables" styles={agentIconStyles} />
              <AgentIcon agent="cults3d" styles={agentIconStyles} />
              <AgentIcon agent="makerworld" styles={agentIconStyles} />
              <AgentIcon agent="myminifactory" styles={agentIconStyles} />
            </div>
          </div>
          <div
            style={{
              backgroundColor: teamsDarkTheme.colorNeutralBackground3,
            }}
          >
            <div
              className={styles.agentH}
              style={{
                gap: "10px",
                padding: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <FaSortAmountDown
                color={teamsDarkTheme.colorNeutralForeground3}
              />
              <Text
                size={400}
                weight="bold"
                style={{
                  color: teamsDarkTheme.colorNeutralForeground3,
                }}
              >
                Filter & Sort
              </Text>
            </div>
          </div>
          <div className={styles.optionsWrap}>
            <Accordion multiple defaultOpenItems={["1", "2"]}>
              <AccordionItem value="1">
                <AccordionHeader>Websites</AccordionHeader>
                <AccordionPanel className={styles.accordionBody}>
                  <div className={styles.agentH}>
                    <AgentIcon
                      agent="thingiverse"
                      styles={{ width: "25px", borderRadius: "10px" }}
                    />
                    <Checkbox
                      checked={providers.thingiverse}
                      onChange={(ev, data) =>
                        handleUpdateProviders(data.checked, "thingiverse")
                      }
                      label="Thingiverse"
                    />
                  </div>
                  <div className={styles.agentH}>
                    <AgentIcon
                      agent="printables"
                      styles={{ width: "25px", borderRadius: "10px" }}
                    />
                    <Checkbox
                      checked={providers.printables}
                      onChange={(ev, data) =>
                        handleUpdateProviders(data.checked, "printables")
                      }
                      label="Printables"
                    />
                  </div>
                  <div className={styles.agentH}>
                    <AgentIcon
                      agent="cults3d"
                      styles={{ width: "25px", borderRadius: "10px" }}
                    />
                    <Checkbox
                      checked={providers.cults3d}
                      onChange={(ev, data) =>
                        handleUpdateProviders(data.checked, "cults3d")
                      }
                      label="Cults 3D"
                    />
                  </div>
                  <div className={styles.agentH}>
                    <AgentIcon
                      agent="makerworld"
                      styles={{ width: "25px", borderRadius: "10px" }}
                    />
                    <Checkbox
                      checked={providers.makerworld}
                      onChange={(ev, data) =>
                        handleUpdateProviders(data.checked, "makerworld")
                      }
                      label="Makerworld"
                    />
                  </div>
                  <div className={styles.agentH}>
                    <AgentIcon
                      agent="myminifactory"
                      styles={{ width: "25px", borderRadius: "10px" }}
                    />
                    <Checkbox
                      checked={providers.myminifactory}
                      onChange={(ev, data) =>
                        handleUpdateProviders(data.checked, "myminifactory")
                      }
                      label="My Mini Factory"
                    />
                  </div>
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem value="2">
                <AccordionHeader>Sorting</AccordionHeader>
                <AccordionPanel className={styles.accordionBody}>
                  <RadioGroup
                    value={sort}
                    onChange={(_, data) => setSort(data.value)}
                  >
                    <Radio value="newest" label="Newest" />
                    <Radio value="oldest" label="Oldest" />
                    <Radio value="aZ" label="A → Z" />
                    <Radio value="zA" label="Z → A" />
                  </RadioGroup>
                </AccordionPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      ) : (
        <div className={styles.mobileDrawer}>
          <div className={styles.mobileOptionsWrap}>
            <Dropdown
              value={"Websites"}
              appearance="underline"
              multiselect={true}
              onChange={(e, data) => console.log(data, e)}
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <div
                className={styles.mobileAgentH}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateProviders(!providers.thingiverse, "thingiverse");
                }}
              >
                <AgentIcon
                  agent="thingiverse"
                  styles={{ width: "25px", borderRadius: "10px" }}
                />
                <Checkbox checked={providers.thingiverse} label="Thingiverse" />
              </div>
              <div
                className={styles.mobileAgentH}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateProviders(!providers.printables, "printables");
                }}
              >
                <AgentIcon
                  agent="printables"
                  styles={{ width: "25px", borderRadius: "10px" }}
                />
                <Checkbox checked={providers.printables} label="Printables" />
              </div>
              <div
                className={styles.mobileAgentH}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateProviders(!providers.cults3d, "cults3d");
                }}
              >
                <AgentIcon
                  agent="cults3d"
                  styles={{ width: "25px", borderRadius: "10px" }}
                />
                <Checkbox checked={providers.cults3d} label="Cults 3D" />
              </div>
              <div
                className={styles.mobileAgentH}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateProviders(!providers.makerworld, "makerworld");
                }}
              >
                <AgentIcon
                  agent="makerworld"
                  styles={{ width: "25px", borderRadius: "10px" }}
                />
                <Checkbox checked={providers.makerworld} label="Makerworld" />
              </div>
              <div
                className={styles.mobileAgentH}
                onClick={(e) => {
                  e.preventDefault();
                  handleUpdateProviders(
                    !providers.myminifactory,
                    "myminifactory"
                  );
                }}
              >
                <AgentIcon
                  agent="myminifactory"
                  styles={{ width: "25px", borderRadius: "10px" }}
                />
                <Checkbox
                  checked={providers.myminifactory}
                  label="My Mini Factory"
                />
              </div>
            </Dropdown>
            <Dropdown
              appearance="underline"
              value={sort}
              onOptionSelect={(e, data) => setSort(data.optionValue)}
              style={{
                display: "flex",
                flex: 1,
              }}
            >
              <Option value="newest" text="Newest">
                Newest
              </Option>
              <Option value="oldest" text="Newest">
                Oldest
              </Option>
              <Option value="aZ" text="Newest">
                A → Z
              </Option>
              <Option value="zA" text="Newest">
                Z → A
              </Option>
            </Dropdown>
          </div>
        </div>
      )}
    </div>
  );
}

export default React.memo(NewSavedFilterSort);
